function fn_libs_reload() {
    var $masonry = $(document).find("[data-masonry-grid]");
    
    if ($masonry.length) {
        $masonry.masonry({
            itemSelector: '.col--masonry',
            columnWidth: '.col--masonry-width',
            percentPosition: true
        })
    }

    sr.sync();
}
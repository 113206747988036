(function($) {
    var $fn = $(".comp_gallery"),
        $gallery = $fn.find("[data-gallery]");

    function fn_comp_gallery_lightgallery($gallery) {
        $gallery.lightGallery({
            thumbnail: true,
            selector: '.part_item_gallery',
            exThumbImage: 'data-exthumbimage',
            actualSize: false,
            download: false
        });
    }

    fn_comp_gallery_lightgallery($gallery);

    window.fn_comp_gallery_lightgallery = fn_comp_gallery_lightgallery;
})(jQuery);
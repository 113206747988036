(function($) {
    var $fn = $(".comp_offers");
    if ($fn.length) {
        fn_comp_about_parallax();

        $(window).resize(function() {
            if ($fn.hasClass("mod--parallax") && $(window).width() < 960) {
                $fn.removeClass("mod--parallax");
                fn_comp_about_parallax();
            } else if (!$fn.hasClass("mod--parallax") && $(window).width() > 960) {
                $fn.addClass("mod--parallax");
                fn_comp_about_parallax();
            }
        });

        $(window).scroll(function () {
            fn_comp_about_parallax();
        });

        function fn_comp_about_parallax() {
            if ($(window).width() > 960) {
                if ($(document).scrollTop() > $fn.offset().top - $("html").height()) {
                    var $scroll = $(document).scrollTop() - ($fn.offset().top - $("html").height());
                }
                $fn.find(".elm_inner > .col:first-of-type").css({
                    'transform': "translate3D(0," + -($scroll / 18) + "px,0)"
                });
            } else {
                $fn.find(".elm_inner > .col").css("transform","");
            }
        }
    }
})(jQuery);
(function($) {
    var $fn = $(".comp_rooms_slider"),
        $slider = $fn.find("[data-slider]"),
        $slider_autoplay = $slider.data("autoplay");

    var $autoplay = true;
    if ($slider_autoplay === "0" || $slider_autoplay === "") {
        $autoplay = false;
    }

    if ($slider.find(".col").length > 3) {
        $slider.slick({
            infinite: true,
            slidesToShow: 4,
            slidesToScroll: 1,
            speed: 500,
            autoplay: $autoplay,
            pauseOnHover: true,
            autoplaySpeed: $slider_autoplay,
            arrows: true,
            prevArrow: '<button type="button" class="slick-prev icon--chevron-left"></button>',
            nextArrow: '<button type="button" class="slick-next icon--chevron-right"></button>',
            dots: false,
            responsive: [
                {
                    breakpoint: 959,
                    settings: {
                        slidesToShow: 3
                    }
                },
                {
                    breakpoint: 768,
                    settings: {
                        slidesToShow: 1
                    }
                }
            ]
        });
    }
})(jQuery);
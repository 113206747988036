(function($) {
    $.fn.validation = function () {
        $(this).removeClass("mod--invalid mod--valid");
        if ($(this).find("input, textarea").val() !== "") {
            if ($(this).find("input, textarea").is(":valid")) {
                $(this).addClass("mod--valid");
            } else {
                $(this).addClass("mod--invalid");
            }
        }
    };

    $(document).on("change", ".part_ui_input", function(){
        if (!$(this).filter("[data-form-datepicker]")) {
            $(this).validation();
        }
    });
    $(".part_ui_input").each(function(){
        if (!$(this).filter("[data-form-datepicker]")) {
            $(this).validation();
        }

    });
})(jQuery);
(function($) {
    var $fn = $(".comp_about");
    if ($fn.length) {

        cssLoaded(function(){
            fn_comp_about_parallax();
        });

        win.on("resize",function() {
            if ($fn.hasClass("mod--parallax") && $(window).width() < 960) {
                $fn.removeClass("mod--parallax");
                fn_comp_about_parallax();
            } else if (!$fn.hasClass("mod--parallax") && $(window).width() > 960) {
                $fn.addClass("mod--parallax");
                fn_comp_about_parallax();
            }
        });

        win.on("scroll",function () {
            fn_comp_about_parallax();
        });

        function fn_comp_about_parallax() {
            if ($(window).width() > 960) {
                if ($(document).scrollTop() > $fn.offset().top - $("html").height()) {
                    var $scroll = $(document).scrollTop() - ($fn.offset().top - $("html").height());
                }
                $fn.find(".wrp_images > .col:nth-of-type(even)").css({
                    'transform': "translate3D(0," + ($scroll / 24) + "px,0)"
                });

                $fn.find(".wrp_images > .col:nth-of-type(odd)").css({
                    'transform': "translate3D(0," + -($scroll / 16) + "px,0)"
                });
            } else {
                $fn.find(".wrp_images > .col").css("transform","");
            }
        }
    }
})(jQuery);
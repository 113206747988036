(function($) {
    const fn = $(".part_nav");

    if (fn.length) {
        fn.each(function() {
            let elm = $(this),
                inner = elm.find("ul"),
                inner_col = inner.find("li"),
                inner_col_width = inner_col.outerWidth(),
                inner_end = inner_col.length*inner_col_width;

            fn_nav_scroll();
            inner.on("scroll",fn_nav_scroll);


            function fn_nav_scroll() {
                if (inner.scrollLeft()+inner.width() > inner_end-inner_col_width/2) {
                    elm.removeClass("mod--state-start").addClass("mod--state-end");
                }
                if (inner.scrollLeft() === 0) {
                    elm.removeClass("mod--state-end").addClass("mod--state-start");
                }
            }
        });
    }
})(jQuery);
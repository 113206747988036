//____ TABS SWITCH CONTENT ____ //
//____ AUTHOR: LUBOMÍR BLAŽEK ____ //
$.fn.nl_lib_switch = function () {
    var $this = $(this),
        $nav = "[data-switch-nav]",
        $area = "[data-switch-area]",
        $nav_item = "[data-nav-item]",
        $area_item = "[data-area-item]";

    $.fn.nl_lib_switch_click = function (n, parent) {
        $(this).on("click", function (e) {
            e.preventDefault();
            $(this).addClass("mod--active").siblings().removeClass("mod--active");

            if (parent == $nav) {
                $(this).closest($this).find($area).children($nav_item).removeClass("mod--active").eq(n).addClass("mod--active");
            } else {
                $(this).closest($this).find($nav).children($nav_item).removeClass("mod--active").eq(n).addClass("mod--active");
            }

            $(this).closest($this).find($area).each(function() {
                $(this).children($area_item).hide().removeClass("mod--active").eq(n).show().addClass("mod--active");
            });
        });
    };

    $this.find($nav).children($nav_item).each(function(n) {
        $(this).nl_lib_switch_click(n,$nav);
    });
    $this.find($area).children($nav_item).each(function(n) {
        $(this).nl_lib_switch_click(n,$area);
    });
};
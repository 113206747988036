(function($) {
    var $fn = $(".comp_offers_detail");

    if ($fn.length) {
        $.getScript(cdnjs.sticky).done(function(){
            $fn.find(".part_ui_arrow").each(function(){
                $(this).stick_in_parent({
                    offset_top: 300
                });
            });
        });
    }
})(jQuery);
(function($) {
    var $timeout,
        $this = $(".part_ui_option");

    $this.find("[aria-expanded]").on("click",function(e){
        var $el = $(this);
        e.preventDefault();
        e.stopPropagation();
        clearTimeout($timeout);

        $.fn.part_lang_close = function() {
            $(this).removeClass("mod--toggled").next("[aria-hidden]").attr("aria-hidden","true");
            $timeout = setTimeout(function(){
                $el.attr("aria-expanded","false");
            },300);
        };
        $.fn.part_lang_open = function() {
            $(this).addClass("mod--toggled").attr("aria-expanded","true").next("[aria-hidden]").removeAttr("aria-hidden");
        };

        $("html").one("click", function() {
            $el.part_lang_close();
        });
        if ($el.hasClass("mod--toggled")) {
            $el.part_lang_close();
        } else {
            $el.part_lang_open();
        }
    });
})(jQuery);
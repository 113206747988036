(function($) {
    var $fn = $(".comp_map"),
        $google_map_api = "https://maps.googleapis.com/maps/api/js?key=AIzaSyDKwI7X1mCMovXCkx0LeEtQNIJfVuvfJPQ";

    function fn_comp_map_init() {
        if ($fn.length && $fn.find(".wrp_comp_content").attr("data-reveal") == "revealed" || $("html").hasClass("ie")) {
            $.getScript($google_map_api).done(function () {
                $fn.find("[data-map]").lib_nl_google_map('init');
                $fn.find("[data-map]").lib_nl_google_map('markers_add');
            });
        }
    }

    fn_comp_map_init();

    window.fn_comp_map_init = fn_comp_map_init;
})(jQuery);
(function($) {
    var $fn = $(".comp_location");

    if ($fn.length) {
        var $elm = $fn.find(".elm_map");
        $(document).on("click", function(e){
            if ($(e.target).is(".elm_map") && $elm.hasClass("mod--active")) {
                $elm.removeClass("mod--active");
            } else {
                $elm.addClass("mod--active");
            }
        });
    }
})(jQuery);